import React, { useState, useEffect } from "react";
import { Button, Container, Pagination, Row, Col, Accordion } from 'react-bootstrap';
import { Table } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import logoImage from "./partner-portal.png";
import "./trip.css"

const Trip = () => {
  const [data, setData] = useState([]);
  const partnerId = localStorage.getItem("partnerid");
  // console.log("partid====", partnerId);
  const today = new Date().toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [showData, setShowData] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // const PARTNER_HOST = `${process.env.REACT_APP_PARTNER_HOST}`;


  useEffect(() => {
    const fetchData = async () => {
      const url = `https://partner-backend.yolobus.in/api/v1/partner/trips?partnerId=${partnerId}&fromDate=${fromDate}&toDate=${toDate}`;
      // console.log("url---", url);

      return fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          // console.log("response====", response);
          return response.json();
        })
        .then(data => {
          setData(data.data.map((item, idx) => {
            return {
              key: idx + 1,
              id: item.id,
              serviceid: item.service_template_id,
              busnumber: item.vehicle_data.registration_number,
              date: item.journey_date,
              servicename: item.service_detail.template_name,
              totalSeats: item.totalSeats,
              totalAmount: item.totalAmount,
            }
          }));
          // console.log("data>>", newresult);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [partnerId, fromDate, toDate]);

  const handleDateFilter = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  async function fetchPNRs(tripId, date) {
    const apiUrl = `https://partner-backend.yolobus.in/api/v1/partner/pnrs?tripId=${tripId}&date=${date}`;
    // console.log(tripId);

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      let newarr =[]
      data.forEach(item => {
        newarr.push({pnrnumber:item.pnr_details.pnr_number, 
          seatnumber: item.pnr_details.seat_numbers.join(', '), 
          passengername:item.pnr_details.seats[0].passanger.name,
          status:item.pnr_details.seats[0].status,
          fare:item.pnr_details.total_seats_base_fare - (item.pnr_details.total_refund || 0),
          gender:item.pnr_details.seats[0].passanger.gender,})
    })
      // data.forEach(item => console.log(item.pnr_details.pnr_number))
      setShowData(newarr)
      // console.log(showData);
      // console.log("resonsepnr>>>>", data[0].metadata[0].total!=0);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error.message);
      throw error;
    }
  }

  const handleTodayFilter = () => {
    const today = new Date().toISOString().split('T')[0];
    handleDateFilter(today, today);
  };

  const handleYesterdayFilter = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayISO = yesterday.toISOString().split('T')[0];
    handleDateFilter(yesterdayISO, yesterdayISO);
  };

  const handleRowClick = (record, index) => {
    const key = record.key; // Assuming each row has a unique key, replace with your actual key property
    const isExpanded = expandedRowKeys.includes(key);

    if (isExpanded) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
      fetchPNRs(record.id, "2024-03-09");
    }
  };

  const expandedRowRender = (record) => {
    // render the expanded content for each row
    // ...
  };

  const rowClassName = (record, index) => {
    return 'clickable-row'; // Add a class to make the row clickable
  };

  const onRow = (record, index) => ({
    onClick: () => handleRowClick(record, index),
  });

  const columns = [
    {
      title: 'Service ID',
      dataIndex: 'serviceid',
      key: 1,
    },
    {
      title: 'Trip ID',
      dataIndex: 'id',
      key: 2
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 3
    },
    {
      title: 'Bus Number',
      dataIndex: 'busnumber',
      key: 4
    },
    {
      title: 'Service Name',
      dataIndex: 'servicename',
      key: 5
    },
    {
      title: 'Total  Seats',
      dataIndex: 'totalSeats',
      key: 6
    },
    {
      title: 'Revenue',
      dataIndex: 'totalAmount',
      key: 7
    },
  ];

  const columns_pnr = [{
    
      title: 'PNR Number',
      dataIndex: 'pnrnumber',
      key: 11,
    },
    {
      title: 'Seat Number',
      dataIndex: 'seatnumber',
      key: 12
    },
    {
      title: 'Passenger Name',
      dataIndex: 'passengername',
      key: 13
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 14
    },
    {
      title: 'Total Fare',
      dataIndex: 'fare',
      key: 15
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 16
    },
  ];

  return (
    <>
    <Navbar/>
      <Container>
        <div className="dateFilter" style={{ marginTop: "20px", marginBottom: "30px", marginLeft: "120px" }}>
          <input
            type="date"
            value={fromDate}
            placeholder="Start Date"
            onChange={(e) => setFromDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
            style={{ marginLeft: '10px', width: '150px', padding: '6px', border: '1px solid #ccc', }}
          />
          <input
            type="date"
            value={toDate}
            placeholder="End Date"
            onChange={(e) => setToDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
            style={{
              marginLeft: '10px',
              width: '150px',
              padding: '6px',
              border: '1px solid #ccc',
            }}
          />
          <Button variant="success" style={{ marginLeft: "20px", marginRight: "20px" }} onClick={() => handleDateFilter(fromDate, toDate)}>Apply Filter</Button>
          <Button variant="primary" onClick={handleTodayFilter} style={{ marginRight: '10px' }}>Today</Button>
          <Button variant="primary" onClick={handleYesterdayFilter}>Yesterday</Button>
        </div>
      </Container>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (

            <Table
            columns={columns_pnr}
            
            dataSource={showData}
          />
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? (
              <CaretUpOutlined style={{ cursor: 'pointer' }} onClick={() => onExpand(record, false)} />
            ) : (
              <CaretDownOutlined style={{ cursor: 'pointer' }} onClick={() => onExpand(record, true)} />
            );
          },
          // onExpand: (record, item) => fetchPNRs(item.id, "2024-03-09"),
          onExpand: (record, item) => {
            if (!item.pnrDataFetched) {
              fetchPNRs(item.id, "2024-03-09");
              // Mark the data as fetched to prevent redundant API calls
              item.pnrDataFetched = true;
            }
          },          
          // onExpand: (record, item) => fetchPNRs('34118', "2024-03-09"),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        dataSource={data}
        expandedRowKeys={expandedRowKeys}
        rowClassName={rowClassName}
        onRow={onRow}
      />
    </>
  );
};

export default Trip;
