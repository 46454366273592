import React, { useState, useEffect } from "react";
import backgroundImage from "./img-01.png";
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import logoImage from "./partner-portal.png";
import { message } from "antd";

// console.log(PARTNER_HOST+PARTNER_HOST);

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [popupStyle, showPopup] = useState("hide");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpLoading, setotpLoading] = useState(false);

  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleGenerateOtp = async () => {
    setOtpSent(true);
    setIsLoading(true);
    setErrorMessage("");


    if (!Number(phoneNumber) || phoneNumber.length < 10 || !(/^[6-9]\d{9}$/.test(phoneNumber))) {
      console.log("Invalid phone number format");
     message.error("10 digit number");
     return;
   }

    try {
      const apiUrl = `https://auth.yolobus.in/v1/auth/partner-login`;
      // console.log("url=======",apiUrl);
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'platform': 'WEPA',
          'user-type': 'partner',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          "phone_code": "+91",
          "phone_number": +phoneNumber


        }),
      };

      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("data---", data)

      if (data.status.http_status_code == 200) {
        setotpLoading(true);
        showPopup("login-popup");
        setTimeout(() => showPopup("hide"), 3000);
      } else {
        setErrorMessage(data.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error('Error generating OTP:', error);
      setErrorMessage("Network error. Please check your connection and try again.");
    } finally {
      // setIsLoading(false);
    }
  };


  const handleVerifyOtp = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      const apiUrl = `https://auth.yolobus.in/v1/auth/validate-otp`;

      // Check if OTP is exactly 4 digits
      if (!(/^\d{4}$/).test(otp)) {
        console.log("Invalid otp format");
        message.error("OTP must be exactly 4 digits");
    }

      const response = await fetch(apiUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'platform': 'WEPA',
          'user-type': 'partner',
          
        },
        credentials: 'include',
        body: JSON.stringify({
          "phone_number": +phoneNumber,
          "otp": +otp,
          "phone_code": "+91",
        })
      });
      // console.log("res====", response);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      // console.log("data=====", data.status.http_status_code);

      if (data.status.http_status_code == 200) {
        // alert("OTP verified successfully!");
        // console.log("data....", data);
        localStorage.setItem("partnerid",data.data.id)
        // localStorage.setItem("partnerid",data.data.partner_id)
        navigate('trip')
      } else {
        setErrorMessage(data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error('Error validating OTP:', error);
      setErrorMessage("Network error. Please check your connection and try again.");
    } finally {
      setIsLoading(false);
    }
  };


  return (

    

    <div className="limiter">
      
      <div className="container-login100">
      <Navbar/>
        <div className="wrap-login100">
          {/* <div className="login100-pic js-tilt" data-tilt style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat' }}> */}

          <form className="login100-form validate-form">
            <span className="login100-form-title">
              Partner Login
            </span>

            <div className="wrap-input100 validate-input" data-validate="Valid number is required">
              <span className="country-code" style={{ marginBottom: '-70px', marginLeft: '10px' }}>
                <ReactCountryFlag countryCode="IN" svg style={{ marginBottom: '-68px' }} />
              </span>

              <input
                className="input100"
                type="text"
                name="Phone Number"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <span className="symbol-input100" style={{ paddingTop: '21px' }}>
                +91
              </span>

            </div>

            <div className="container-login100-form-btn">
              <button type="button" className="login100-form-btn" onClick={handleGenerateOtp}>
                Get OTP
              </button>
            </div>
            {otpSent && (
              <div style={{ textAlign: "center", color: "#008000" }}>
                4 digit OTP has been sent to <b>{phoneNumber}</b>
              </div>
            )}
            <div className="text-center p-t-136">
              {/* <a className="txt2" href="#">
                Create your Account
                <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
              </a> */}
            </div>
          </form>
          {otpLoading ? <>
            <div className="wrap-input100 validate-input" data-validate="Valid OTP is required">
              <input className="input100" type="text" name="OTP" value={otp} onChange={handleOtpChange} placeholder="Enter OTP" />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={handleVerifyOtp}>
                Verify OTP
              </button>
            </div>
          </> : null}

        </div>
      </div>
    </div>

  );
};

export default LoginForm;
