// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickable-row {
    cursor: pointer;
  }
  
  .clickable-row:hover {
    background-color: #f5f5f5; 
  }
  
  .ant-table-row-expand-icon-cell {
    background-color: #e6f7ff; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/trip.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".clickable-row {\n    cursor: pointer;\n  }\n  \n  .clickable-row:hover {\n    background-color: #f5f5f5; \n  }\n  \n  .ant-table-row-expand-icon-cell {\n    background-color: #e6f7ff; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
