import Menu from "./components/menu";
import CustomRoute from "./components/routes";


function App() {
  return (
    <div>
      <Menu/>
    
    <div>
      <CustomRoute/>
      </div>
    </div>
  );
}

export default App;
