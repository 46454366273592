import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LoginForm from './loginform';
import Trip from './trip';

const CustomRoute = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/trip" element={<Trip />} />
        <Route exact path="/" element={<LoginForm />} />
        <Route path="*" element={() => <div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default CustomRoute;
