import React from 'react';
import {  useState } from "react";
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import logoImage from "./partner-portal.png";

const Menu = () => {
  // console.log("hello");
  const [logout, setLogout] = useState(true);


  const handleLogout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl = 'https://auth.yolobus.in/v1/auth/logout';
  
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',  
          },
          credentials: 'include',
        });
        setLogout(true)
        if (response.ok) {
          localStorage.removeItem("lgni_usr_info");
          localStorage.removeItem("partnerid")
          window.location.href = '/'; 
          resolve(); 
        } else {
          const errorMessage = `Logout failed: ${response.statusText}`;
          console.error(errorMessage);
          reject(errorMessage);
        }
      } catch (error) {
        const errorMessage = `Error during logout: ${error.message || error}`;
        console.error(errorMessage);
        reject(errorMessage);
      }
    });
  };

  return (
    <Navbar style={{ background: '#000' }} variant="dark" expand="lg">
      <Navbar.Brand href="" style={{ display: 'flex', alignItems: 'center'}}>
      <div>
    <Image src={logoImage} fluid style={{ width: '130px', height: '50px' }} />
  </div>
    </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {localStorage.getItem("partnerid")!=null?
            <>
            <Nav.Link href="/trip">Trip Details</Nav.Link>
            <Nav.Link href="">Annexure (Coming Soon)</Nav.Link>
            <Nav.Link href="">NPS (Coming Soon)</Nav.Link>
            <Nav.Link href="">OPS Issue (Coming Soon)</Nav.Link>
            <Nav.Link>Partner Id: {localStorage.getItem("partnerid")}</Nav.Link>
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </>
            :<Nav.Link href="">Login</Nav.Link>
            
          }
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
